export default [
  "A",
  "AS",
  "OS",
  "NA",
  "NO",
  "NAS",
  "NOS",
  "DE",
  "DA",
  "DO",
  "DAS",
  "DOS",
  "PARA",
  "COM",
  "COMO",
  "a",
  "ante",
  "até",
  "após",
  "com",
  "contra",
  "de",
  "desde",
  "em",
  "entre",
  "para",
  "perante",
  "por",
  "sem",
  "sob",
  "sobre",
  "trás",
  "the",
  "by",
  "at",
  "on",
  "in",
  "about",
  "with",
  "from",
  "below",
  "A",
  "O",
  "De",
  "Na",
  "Em",
  "Um",
  "Uma",
].map((item) => item.toLowerCase());
