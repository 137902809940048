import aspas from "./desktop_resultado_aspas_criativo.png"
import fundo from "./Desktop-Resultado-background-criativo.png"
import arquitetura from "./Desktop-resultado-criativo-arquitetura.png"
import artesPlasticas from "./Desktop-resultado-criativo-artes-plasticas.png"
import artesVisuais from "./Desktop-resultado-criativo-artes-visuais.png"
import cinema from "./Desktop-resultado-criativo-cinema.png"
import desenhoIndustrial from "./Desktop-resultado-criativo-desenho-industrial.png"
import designDeGames from "./Desktop-resultado-criativo-design-de-games.png"
import designGrafico from "./Desktop-resultado-criativo-design-grafico.png"
import moda from "./Desktop-resultado-criativo-moda.png"
import multimidia from "./Desktop-resultado-criativo-multimidia.png"
import iconeDoFundo from "./resultado_icone_criativo_lampada.png"

export default {
  fundo,
  aspas,
  arquitetura,
  artesPlasticas,
  artesVisuais,
  cinema,
  desenhoIndustrial,
  designDeGames,
  designGrafico,
  moda,
  multimidia,
  iconeDoFundo,
};
