import aspas from "./desktop_resultado_aspas_racional.png"
import fundo from "./Desktop-Resultado-background-racional.png"
import administracao from "./Desktop-resultado-racional-administracao.png"
import analiseDeSistemas from "./Desktop-resultado-racional-analise-de-sistemas.png"
import astronomia from "./Desktop-resultado-racional-astronomia.png"
import biomedicina from "./Desktop-resultado-racional-biomedicina.png"
import cienciaDaComputacao from "./Desktop-resultado-racional-ciencia-da-computacao.png"
import cienciasAtuariais from "./Desktop-resultado-racional-ciencias-atuariais.png"
import contabilidade from "./Desktop-resultado-racional-contabilidade.png"
import economia from "./Desktop-resultado-racional-economia.png"
import engenhariaCivil from "./Desktop-resultado-racional-engenharia-civil.png"
import engenhariaDeProducao from "./Desktop-resultado-racional-engenharia-de-producao.png"
import engenhariaDeSoftware from "./Desktop-resultado-racional-engenharia-de-software.png"
import estatistica from "./Desktop-resultado-racional-estatistica.png"
import marketing from "./Desktop-resultado-racional-marketing.png"
import matematicaIndustrial from "./Desktop-resultado-racional-matematica-industrial.png"
import iconeDoFundo from "./resultado_icone_racional.png"

export default {
  fundo,
  aspas,
  administracao,
  analiseDeSistemas,
  astronomia,
  biomedicina,
  cienciaDaComputacao,
  cienciasAtuariais,
  contabilidade,
  economia,
  engenhariaCivil,
  engenhariaDeProducao,
  engenhariaDeSoftware,
  estatistica,
  marketing,
  matematicaIndustrial,
  iconeDoFundo,
};
