import fundo from "./Desktop-Resultado-background-comunicativo.png";
import aspas from "./desktop_resultado_aspas_comunicativo.png";
import biblioteconomia from "./Desktop-resultado-comunicativo-biblioteconomia.png";
import cienciasSociais from "./Desktop-resultado-comunicativo-ciencias-sociais.png";
import filosofia from "./Desktop-resultado-comunicativo-filosofia.png";
import geografia from "./Desktop-resultado-comunicativo-geografia.png";
import historia from "./Desktop-resultado-comunicativo-historia.png";
import jornalismo from "./Desktop-resultado-comunicativo-jornalismo.png";
import letras from "./Desktop-resultado-comunicativo-letras.png";
import pedagogia from "./Desktop-resultado-comunicativo-pedagogia.png";
import radioTvInternet from "./Desktop-resultado-comunicativo-radio-tv-internet.png";
import relacoesPublicas from "./Desktop-resultado-comunicativo-relacoes-publicas.png";
//TODO: trocar para comunicativo
import iconeDoFundo from "../../../../balao.png";

export default {
  fundo,
  aspas,
  biblioteconomia,
  cienciasSociais,
  filosofia,
  geografia,
  historia,
  jornalismo,
  letras,
  pedagogia,
  radioTvInternet,
  relacoesPublicas,
  iconeDoFundo,
};
