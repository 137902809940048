import styled from "styled-components";

export const Nav = styled.nav`
  height: 60px;
  width: 100%;

  background-color: #171715;

  color: #fff;
`;
export const WrapperNav = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  > a > img {
    width: 170px;
    height: 30px;
  }
`;
