import aspas from "./desktop_resultado_aspas_pratico.png";
import fundo from "./Desktop-Resultado-background-pratico.png";
import agronomia from "./Desktop-resultado-pratico-agronomia.png";
import artesCenicas from "./Desktop-resultado-pratico-artes-cenicas.png";
import danca from "./Desktop-resultado-pratico-danca.png";
import educacaoFisica from "./Desktop-resultado-pratico-educacao-fisica.png";
import estetica from "./Desktop-resultado-pratico-estetica.png";
// import fisioterapia from "./Desktop-resultado-pratico-fisioterapia.png";
import gastronomia from "./Desktop-resultado-pratico-gastronomia.png";
import pilotoDeAviao from "./Desktop-resultado-pratico-piloto-de-aviao.png";
import pilotoDeDrones from "./Desktop-resultado-pratico-piloto-de-drones.png";
import zootecnia from "./Desktop-resultado-pratico-zootecnia.png";
import iconeDoFundo from "./resultado_icone_pratico.png";

export default {
  fundo,
  aspas,
  agronomia,
  artesCenicas,
  danca,
  educacaoFisica,
  estetica,
  // fisioterapia,
  gastronomia,
  pilotoDeAviao,
  pilotoDeDrones,
  zootecnia,
  iconeDoFundo,
};
